import logo from 'assets/images/neozorg_logo.png';
import logoPayoff from 'assets/images/neozorg_payoff.svg';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:82f95abe-e883-4afc-8555-6e0d541d704f',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_ZiFqO9JQO',
  aws_user_pools_web_client_id: '157fubr20pneas4ciajn916867',
  oauth: {
    domain: 'neozorg.auth.eu-west-1.amazoncognito.com',
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://www.neozorg.clinicards.app/',
    redirectSignOut: 'https://neozorg.clinicards.app/s',
    responseType: 'code',
  },
  aws_appsync_graphqlEndpoint:
    'https://onmv6njgfrc75meppgys36yqom.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://6hznu3noa8.execute-api.eu-west-1.amazonaws.com/neo',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage102411-neo',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_public_images_bucket: 'publicarticleimages102411-neo',
  aws_public_images_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'neozorg.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'NeoZorg',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl', 'en'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_INSIGHTS: false,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_CHILD_PROFILE: true,
  ENABLE_STACKED_CAREPATH: true,
  ENABLE_HIDE_PATIENT_BIRTHDATE: true,
  REMOVE_GENDER_FROM_ONBOARDING: true,
  ENABLE_USER_REINVITATION: true,
  ENABLE_PATIENT_TRANSFER: false,
  ENABLE_PERSONAL_MESSAGES: true,
  AWS,
  logo,
  logoPayoff,
  defaultCarepathCards,
  ENABLE_LIST_CHILD_NAME: true,
  DEFAULTCARDS: [
    'caa35515-97a1-4139-abe4-b4ca8190933f',
    '62369046-980d-4ea6-9e78-1b1b69068e08',
    'de673886-5669-4e0d-b142-6fda146d2334',
    '7c91f545-4075-40a1-8f27-1e393b06bca4',
    '9a850f84-eae3-4815-b7a2-d9a54a0c53c6',
    '8bc940b6-cea7-4445-bf8a-17d8cb552262',
    '72d83d8f-bc4e-470c-8150-dfe5de6fe303',
    '173e478c-057b-493a-b797-9dbc4049ae41',
    '54wsa12g-cea7-9923-bf8a-17d8cb552262',
  ],
  SSO_ORGANISATIONS: [
    {
      hospital: 'Amphia Ziekenhuis',
      provider: 'AmphiaADProvider',
      orgid: '4b69079b-16a1-465d-a427-39c7b2123fe4',
    },
    {
      hospital: 'Franciscus Gasthuis',
      provider: 'FranciscusADProvider',
      orgid: '080468c9-07aa-4e59-af82-6d0fc9e55400',
    },
    {
      hospital: 'OLVG',
      provider: 'OLVGADProvider',
      orgid: '7d3f40a0-c02d-4e8f-bbe9-34b10eadea42',
    },
  ],
  SSO_PROVIDER: 'AmphiaADProvider',
  ENABLE_OPENID_SSO: false,
  ENABLE_AZURE_SSO: true,
  ENABLE_VPK: true,
  ENABLE_USAGE_ACTIVITY: true,
  VISUALISE_INACTIVE_USERS: true,
  ENBALE_NEW_MESSAGE_CENTER_NAME: true,
};
