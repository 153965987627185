import logo from 'assets/images/ouderkind_logo.png';
import logoPayoff from 'assets/images/ouderkind_payoff.png';
import favicon from 'assets/images/ouderKind_favicon.png';
import favicon16 from 'assets/images/ouderkind_favicon-16x16.png';

import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:7ce132ad-f576-47bc-a77f-1817102dca67',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_d9RJfPee7',
  aws_user_pools_web_client_id: '6imgds8feahmd422o4b8eoglff',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://qqv2hcngznbcpla4r56pchsjyi.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://cx6pw0yeee.execute-api.eu-west-1.amazonaws.com/ouderkind',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage134505-ouderkind',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_public_images_bucket: 'publicarticleimages164947-ouderkind',
  aws_public_images_bucket_region: 'eu-west-1',
  Auth: {
    cookieStorage: {
      domain:
        process.env.NODE_ENV === 'production'
          ? 'ouderkind.clinicards.app'
          : 'localhost',
      path: '/',
      expires: 1,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'MMC Ouderkind',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl', 'en'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_INSIGHTS: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_CHILD_PROFILE: true,
  ENABLE_STACKED_CAREPATH: true,
  ENABLE_LIST_CHILD_NAME: true,
  REMOVE_GENDER_FROM_ONBOARDING: true,
  ENABLE_HIDE_PATIENT_BIRTHDATE: true,
  ENABLE_USER_REINVITATION: true,
  AWS,
  logo,
  logoPayoff,
  defaultCarepathCards,
  favicon,
  favicon16,
  DEFAULTCARDS: [
    '7c91f545-4075-40a1-8f27-1e393b06bca4',
    '54wsa12g-cea7-9923-bf8a-17d8cb552262',
    '173e478c-057b-493a-b797-9dbc4049ae41',
    'caa35515-97a1-4139-abe4-b4ca8190933f',
    '72d83d8f-bc4e-470c-8150-dfe5de6fe303',
    'de673886-5669-4e0d-b142-6fda146d2334',
    '348sfjkdh-hj23-9172-kl32-lksdjf9sdfl',
    '8dc61d00-783e-4a9f-8b2c-d9769c68fbf8',
    '9a850f84-eae3-4815-b7a2-d9a54a0c53c6',
  ],
  SHOW_PHOTOS: false,
  ENABLE_USAGE_ACTIVITY: true,
  ENABLE_PERSONAL_MESSAGES: true,
  VISUALISE_INACTIVE_USERS: true,
  INSIGTHSCARDORDER: [
    'baby_nutrition',
    'pump_hc_name',
    'breastfeeding_hc_name',
    'weight_infant_hc_name',
    'experience_pumping',
    'breastfeeding_experience',
    'buidelen',
    'milestones_hc_name',
    'experienced_stress_hc_name',
    'stress_questionnaire_hc_name',
  ],
};
