import { CLINICARD_NAMES } from 'constants/constants';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:c1847e79-675f-4886-8ae2-44f772bbe7dc',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_tQCLrehqB',
  aws_user_pools_web_client_id: '2d3uqf1d4r1i4bhn26u038t82u',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://latylggszbekda5cy2upwsfjiq.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-fakeApiId123456',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint:
        'https://wuno61t10b.execute-api.eu-west-1.amazonaws.com/sandboks',
      region: 'eu-west-1',
    },
  ],
  aws_user_files_s3_bucket: 'clinicardstorage131455-sandboks',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_public_images_bucket: 'publicarticleimages131455-sandboks',
  aws_public_images_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [
  'bmi',
  'prosthesis_questionnaire',
  'prepare_visit',
  'mood',
  'caregiver_strain_index',
  'vas_painscore',
  'steps',
  'photo',
  'exercises',
  'goals',
  'temperature',
  'blood_pressure',
  'heartrate',
  'saturation',
  'vas_painscore',
  'treatment_questionnaire',
  'information_questionnaire',
  'eating_questionnaire',
  'exercises_questionnaire',
  'breastfeeding',
];

const medProfileQuestions = ['length'];

export default {
  ...defaultConfigs,
  APP_TITLE: 'SANDBOX',
  APP_CODE: 'CLIN-APP-0000',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl', 'en'],
  ENABLE_CE: true,
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_STACKED_CAREPATH: true,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_PERSONAL_MESSAGES: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_OPERATION_PROFILE: true,
  ENABLE_STICKY_CAREPATHITEM: true,
  ENABLE_ACTIVATIONPERIOD_CAREPATHITEM: true,
  ENABLE_RANDOMISATION: true,
  ENABLE_CAREPATHS_ARTICLES: true,
  ENABLE_INSTANCE_SPECIC_NAMING: true,
  ENABLE_USER_REINVITATION: true,
  INSIGHTS_GRAPH_WHITELIST: [CLINICARD_NAMES.CSI],
  AWS,
  defaultCarepathCards,
  MEDICAL_PROFILE_QUESTIONS: medProfileQuestions,
  ENABLE_USAGE_ACTIVITY: true,
  ENABLE_ANALYSE: true,
  VISUALISE_INACTIVE_USERS: true,
  ENABLE_COPY_SCRIPT: 'f2097780-5b94-4e09-a401-2fbb75b7c632',
};
